<template>
  <div class="flex w-full items-start overflow-hidden">
    <div
      class="flex-shrink-0 transition-all duration-300 overflow-hidden"
      :class="[modelValue ? openWidth : closedWidth, sidebar]"
    >
      <slot name="sidebar">
        <div class="h-64">
          <div @click="emit('update:modelValue', false)">Close</div>
        </div>
      </slot>
    </div>
    <slot name="separator">
      <div class="w-5"></div>
    </slot>

    <div class="flex-grow">
      <slot name="content">
        <div class="h-64 bg-gray-200">
          <div v-if="!modelValue" @click="emit('update:modelValue', true)">
            Open
          </div>
        </div>
      </slot>
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps({
  modelValue: { type: Boolean, default: true },
  openWidth: { type: String, default: 'w-52' },
  closedWidth: { type: String, default: 'w-10' },
  sidebar: { type: String, default: 'bg-gray-200' }
})

const emit = defineEmits(['update:modelValue'])
</script>
